.section-title,
.section-title.MuiTypography-root {
  margin-bottom: 50px;
  text-transform: capitalize;
}

.JoyTypography-root {
  font-family: "Poppins", sans-serif !important;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  font-family: "Merriweather", serif !important;
}

h1,
.h1,
h2,
.h2 {
  margin-top: 0;
  line-height: 1.2em;
  letter-spacing: -3.5px;
  font-weight: 500;
}
h1,
.h1,
.MuiTypography-root.h2 {
  font-size: 54px;
}
h2,
.h2,
.MuiTypography-root.h2 {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: -0.13px;
}
h3,
.h3,
.MuiTypography-root.h3 {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.27px;
}
.MuiTypography-h5 {
  font-weight: 500 !important;
  font-size: 18px !important;
}

@media screen and (max-width: 768px) {
  h2,
  .h2 {
    font-size: 24px;
  }

  h4,
  .h4 {
    font-size: 24px;
  }
}
