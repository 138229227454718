.cards {
  margin: 25px 0;
}

.card {
  display: flex;
  position: relative;
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 2px 8px 0px rgba(99, 99, 99, 0.2);
  padding: 20px;
  margin-top: 30px;
  transition: ease-in-out all 0.2s;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.45);
}
.card:hover {
  transform: translateY(-10px);
  background-color: #3c2ea9;
}

.card .featured-image {
  border-radius: 16px;
  overflow: hidden;
  max-width: 150px;
}

.card .featured-image img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

/*
* Main content 
*/
.card .details {
  width: calc(100% - 300px);
}
.card .details .name {
  margin: 0;
  display: flex;
  align-items: center;
}
.card .details .desc {
  font-size: 12px;
  line-height: 1.2em;
}

.card .details .mission {
  padding-left: 30px;
  padding-right: 200px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.card .details .mission > *:not(:last-child) {
  margin-bottom: 10px;
}
.card .details .target-date {
  font-size: 12px;
}

.card .chips > *:not(:last-child) {
  margin-right: 5px;
}

.JoyChip-colorNeutral {
  background-color: #6f737c !important;
}
.JoyChip-colorSuccess {
  background-color: #4e9f3f !important;
}

.card.expeditions .patch {
  max-width: 150px;
  height: auto;
  margin-left: 10px;
}

/* 
* Date
*/
.card .target-date {
  width: 150px;
  min-height: 125px;
  text-align: center;
  border-radius: 16px;
  color: black;
}
.card .target-date .inner {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding: 50px 15px;
  text-align: center;
  border-radius: 16px;
}
.card .date {
  font-size: 14px;
  text-transform: uppercase;
}
.card .time {
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
}
.card.launches .target-date .inner {
  background-color: #edf8eb;
}
.card.card.expeditions .target-date .inner {
  background-color: #eceef9;
}

@media screen and (max-width: 768px) {
  .card .details .mission {
    padding-right: 30px;
  }
}

@media screen and (max-width: 650px) {
  .card {
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0;
  }
  .card .target-date {
    width: 100%;
    order: 3;
    min-height: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card .target-date .inner {
    top: auto;
    transform: none;
    padding: 20px;
    border-radius: 0;
  }
  .card .details {
    width: 100%;
    order: 2;
    padding: 20px;
  }
  .card .featured-image {
    max-width: none;
    order: 1;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card .details .mission {
    top: auto;
    transform: none;
    padding: 0;
  }
}
